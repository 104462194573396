export const reduxActionTypes = {
    /**
     * USER ACTION TYPE
     */
    SET_USER: "SET_USER",
    GET_USER: "GET_USER",
    REMOVE_USER: "REMOVE_USER",
    SET_ALL_USER: "SET_ALL_USER",

    /**
     * CATEGORY ACTION TYPE
     */
    GET_CATEGORY: "GET_CATEGORY",
    SET_CATEGORY_TAB_INDEX: "SET_CATEGORY_TAB_INDEX",
    SET_ITEM_LIST: "SET_ITEM_LIST",

    /**
     * SUBSCRIPTION ACTION TYPE
     */
    GET_SUBSCRIPTION_LIST: "GET_SUBSCRIPTION_LIST",

    /**
     * EVENT ACTION TYPE
     */
    SET_EVENT_ITEM_LIST: "SET_EVENT_ITEM_LIST",

    /**
     * BLOG ACTION TYPE
     */
    SET_BLOG_ITEM_LIST: "SET_BLOG_ITEM_LIST",

    /**
     * QUALITY ACTION TYPE
     */
    GET_QUALITY_CATEGORY: "GET_QUALITY_CATEGORY",
    SET_QUALITY_CATEGORY_TAB_INDEX: "SET_QUALITY_CATEGORY_TAB_INDEX",
    SET_QUALITY_ITEM_LIST: "SET_QUALITY_ITEM_LIST",
}