import React from "react";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import {store} from 'reducers'

import AppRoutes from "routes/AppRoutes";

import "react-toastify/dist/ReactToastify.css";
import 'simplebar/dist/simplebar.min.css';

import './styles/App.scss';

function App() {
    return (
        <>
            <Provider store={store}>
                <BrowserRouter>
                    <AppRoutes/>
                </BrowserRouter>
            </Provider>
            <ToastContainer
                position="bottom-right"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                className="app-toast-container"
            />
        </>
    );
}

export default App;
