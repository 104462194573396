import React, {lazy, Suspense} from 'react';
import {Route, Switch} from 'react-router-dom'
import {connect} from 'react-redux'

import Loader from "../components/Loader/Loader";


const Login = lazy(() => import('containers/Auth/Login/Login'));

const Admin = lazy(() => import('containers/Admin'));
const Dashboard = lazy(() => import('containers/Pages/Dashboard/Dashboard'));
const Category = lazy(() => import('containers/Pages/Category/Category'));
const User = lazy(() => import('containers/Pages/User/User'));
const Verify = lazy(() => import('containers/Auth/Verify/Verify'));
const PageNotFound = lazy(() => import('containers/Pages/PageNotFound/PageNotFound'));
const Subscription = lazy(() => import('containers/Pages/Subscription/Subscription'));
const Quality = lazy(() => import('containers/Pages/Quality/Quality'));
const Events = lazy(() => import('containers/Pages/Events/Events'));
const Blog = lazy(() => import('containers/Pages/Blog/Blog'));

const AppRoutes = (props) => {
    const isLogin = props.token ? true : false;
    return (
        <Switch>
            <Route
                exact
                path={["/verify/:email&:code"]}
                render={(props) =>
                    <Suspense fallback={<Loader/>}>
                        <Verify {...props} />
                    </Suspense>
                }
            />
            <Route
                exact
                path={["/","/category",'/user','/subscription','/quality-of-life','/event','/blog']}
                render={(props) =>

                    isLogin ? (
                        <Suspense fallback={<Loader/>}>
                            <Admin {...props}>
                                <Route exact path="/" component={Dashboard}/>
                                <Route exact path="/category" component={Category}/>
                                <Route exact path="/event" component={Events}/>
                                <Route exact path="/blog" component={Blog}/>
                                <Route exact path="/quality-of-life" component={Quality}/>
                                <Route exact path="/user" component={User}/>
                                <Route exact path="/subscription" component={Subscription}/>
                            </Admin>
                        </Suspense>
                    ) : (
                        <Suspense fallback={<Loader/>}>
                            <Login {...props} />
                        </Suspense>
                    )

                }
            />
            <Route
                exact
                path={"*"}
                render={(props) =>
                    <Suspense fallback={<Loader/>}>
                        <PageNotFound {...props} />
                    </Suspense>
                }
            />

        </Switch>
    );
};
const mapStateToProps = (state) => ({
        token: state.user.token
    }
)

export default connect(mapStateToProps)(AppRoutes)