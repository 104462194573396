import {createStore, combineReducers, applyMiddleware} from "redux"
import reduxThunk from "redux-thunk"

import userReducer from './userReducer';
import categoryReducer from './categoryReducer';
import subscriptionReducer from './subscriptionReducer';
import qualityReducer from './qualityReducer';
import eventReducer from './eventReducer';
import blogReducer from './blogReducer';

const reducers = combineReducers({
    user: userReducer,
    category: categoryReducer,
    subscription: subscriptionReducer,
    quality: qualityReducer,
    event: eventReducer,
    blog: blogReducer,
});


const initialState = {
    user: {
        list: null,
        data: null,
        token: JSON.parse(localStorage.getItem('userData')) || null,
    },
    category: {
        categories: null,
        categoryTabIndex: 1,
        list: null
    },
    subscription: {
        list: null
    },
    quality: {
        categories: null,
        categoryTabIndex: null,
        list: null
    },
    event: {
        list: null
    },
    blog: {
        list: null
    }

};

export const store = createStore(reducers, initialState, applyMiddleware(reduxThunk));