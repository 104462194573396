import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import { MuiThemeProvider, createTheme  } from '@material-ui/core'

import App from './App';

const theme = createTheme ({
    palette: {
        primary: {
            light: '#273026',
            main: '#394537',
            dark: '#606a5f',
            contrastText: '#fff',
        }
    }
})

ReactDOM.render(

        <MuiThemeProvider theme={theme}>
            <App/>
        </MuiThemeProvider>,

    document.getElementById('root')
);

