import {reduxActionTypes} from './reduxActionTypes'

const initialState = {
    list: null
}

const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case reduxActionTypes.GET_SUBSCRIPTION_LIST:
            return {
                ...state,
                list: action.payload.list,
            };
        default:
            return state;
    }
}

export default subscriptionReducer;