import {reduxActionTypes} from './reduxActionTypes'

const initialState = {
    data: null,
    token: JSON.parse(localStorage.getItem('userData')) || null,
    list: null
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case reduxActionTypes.SET_USER:
            return {
                ...state,
                data: action.payload.user,
                token: action.payload.token,
            };
        case reduxActionTypes.REMOVE_USER:
            return {
                ...state,
                data: null,
                token: null
            };
        case reduxActionTypes.SET_ALL_USER:
            return {
                ...state,
                list: action.payload.list
            };
        default:
            return state;
    }
}

export default userReducer;