import {reduxActionTypes} from './reduxActionTypes'

const initialState = {
    categories: null,
    categoryTabIndex: 1,
    list: null
}

const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case reduxActionTypes.GET_CATEGORY:
            return {
                ...state,
                categories: action.payload.categories,
            };
        case reduxActionTypes.SET_CATEGORY_TAB_INDEX:
            return {
                ...state,
                categoryTabIndex: action.payload.categoryTabIndex,
            };
        case reduxActionTypes.SET_ITEM_LIST:
            return {
                ...state,
                list: action.payload.list,
            };
        default:
            return state;
    }
}

export default categoryReducer;